.mod {
  width: 100vw;
  height: 384.38vw;
  font-family: PingFang SC;
}
.group {
  position: relative;
  align-items: flex-start;
  width: 100vw;
  background-color: #ffffff;
}
.divider {
  position: absolute;
  top: 256.72vw;
  align-self: center;
  width: 79.9vw;
  height: 0.1vw;
}
.header {
  position: relative;
  align-items: flex-start;
  background-color: #f3f8fd;
}
.block {
  position: relative;
  align-items: center;
  height: 4.64vw;
  background-color: #ffffff;
  line-height: 0.83vw;
  white-space: nowrap;
}
.container {
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 4.48vw;
  margin-top: -0.16vw;
  background-color: #ffffff;
}
.icon-circle {
  width: 1.61vw;
  height: 1.88vw;
  margin-right: 1.15vw;
  line-height: normal;
  white-space: normal;
}
.wrapper {
  align-items: center;
  margin-top: -0.1vw;
  margin-right: 2.92vw;
  line-height: normal;
  white-space: normal;
}
.empty {
  width: 0;
  height: 1.04vw;
  margin-right: 0.05vw;
}
.group-1 {
  align-items: flex-end;
}
.vertical-line {
  width: 0.05vw;
  height: 1.04vw;
  margin-left: -0.05vw;
  margin-right: 0.83vw;
  background-color: #254478;
}
.info {
  margin-bottom: NaN;
  font-size: 0.63vw;
  font-weight: 300;
  line-height: 0.63vw;
  color: #254478;
  text-align: left;
  white-space: nowrap;
}
.tag {
  margin-right: 3.33vw;
  font-size: 0.83vw;
  font-weight: 500;
  color: #5c5c5c;
  text-align: left;
}
.label {
  margin-right: 3.33vw;
  font-size: 0.83vw;
  color: #5c5c5c;
  text-align: left;
}
.word {
  margin-right: 3.33vw;
  font-size: 0.83vw;
  color: #5c5c5c;
  text-align: left;
}
.download {
  margin-right: 3.33vw;
  font-size: 0.83vw;
  color: #5c5c5c;
  text-align: left;
}
.tag-1 {
  margin-right: 3.33vw;
  font-size: 0.83vw;
  color: #5c5c5c;
  text-align: left;
}
.label-1 {
  margin-right: 3.33vw;
  font-size: 0.83vw;
  color: #5c5c5c;
  text-align: left;
}
.word-1 {
  margin-right: 17.14vw;
  font-size: 0.83vw;
  color: #5c5c5c;
  text-align: left;
}
.tag-wrapper {
  align-items: center;
  height: 1.93vw;
  padding-left: 2.03vw;
  padding-right: 2.03vw;
  margin-top: -0.05vw;
  /* background-color: #ffb445; */
  font-weight: 500;
  line-height: 0.78vw;
  border-radius: 0.21vw;
  box-sizing: border-box;
  border-width: 0.05vw;
  border-style: solid;
  border-color: rgba(255, 137, 52, 1);
}
.tag-2 {
  font-size: 0.78vw;
  color: #ff8934;
  text-align: left;
}
.block-1 {
  position: relative;
  align-items: flex-end;
  margin-top: 3.39vw;
}
.horizontal-line {
  position: absolute;
  right: 4.53vw;
  bottom: 0.1vw;
  width: 2.4vw;
  height: 0.16vw;
}
.large-icon {
  position: absolute;
  right: 10.21vw;
  bottom: 0.31vw;
  width: 3.65vw;
  height: 0.1vw;
}
.horizontal-line-1 {
  position: absolute;
  right: 24.69vw;
  bottom: 0.26vw;
  width: 2.81vw;
  height: 0.1vw;
}
.container-1 {
  position: relative;
  align-items: flex-end;
  margin-right: 2.86vw;
  font-weight: 500;
  white-space: nowrap;
}
.banner {
  position: relative;
  width: 46.04vw;
  height: 27.14vw;
  white-space: normal;
}
.summary {
  position: absolute;
  top: 14.64vw;
  right: 11.04vw;
  font-size: 0.83vw;
  color: #888888;
  text-align: justified;
}
.wrapper-1 {
  position: absolute;
  left: 15.26vw;
  top: 18.23vw;
  align-items: center;
  height: 1.93vw;
  padding-left: 3.65vw;
  padding-right: 3.65vw;
  background-color: #ff8934;
  line-height: 0.78vw;
  border-radius: 0.21vw;
  box-sizing: border-box;
}
.download-1 {
  font-size: 0.78vw;
  color: #ffffff;
  text-align: left;
}
.summary-1 {
  position: absolute;
  left: 15.21vw;
  top: 12.81vw;
  font-size: 1.25vw;
  font-weight: 500;
  color: #888888;
  text-align: justified;
  white-space: pre;
}
.layer {
  position: relative;
  width: 43.07vw;
  height: 27.92vw;
}
.title {
  position: absolute;
  left: 15.21vw;
  top: 2.92vw;
  font-size: 3.13vw;
  font-weight: 600;
  color: #222222;
  text-align: left;
  white-space: pre;
}
.book {
  position: relative;
  align-self: center;
  margin-top: 5.1vw;
  font-size: 2.08vw;
  color: #ed692c;
  text-align: left;
  white-space: nowrap;
}
.article {
  position: relative;
  display: -webkit-inline-box;
  overflow: hidden;
  width: 75.26vw;
  height: 8.33vw;
  margin-top: 2.92vw;
  margin-left: 14.58vw;
  font-size: 1.04vw;
  color: #666666;
  text-align: center;
  white-space: normal;
  text-overflow: ellipsis;
  webkit-box-orient: vertical;
  webkit-line-clamp: 5;
}
.body-item-0 {
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 5vw;
  margin-top: 6.04vw;
}
.floor-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 5vw;
}
.caption {
  position: relative;
  margin-top: -0.21vw;
  margin-right: 2.03vw;
  font-size: 1.67vw;
  line-height: 1.67vw;
  color: #ffffff;
  text-align: left;
  white-space: pre;
}
.block-2 {
  position: relative;
  align-items: center;
  height: 1.93vw;
  padding-left: 2.03vw;
  padding-right: 2.03vw;
  margin-top: -0.05vw;
  /* background-color: #ffb445; */
  font-weight: 500;
  line-height: 0.78vw;
  white-space: nowrap;
  border-radius: 0.21vw;
  box-sizing: border-box;
  border-width: 0.05vw;
  border-style: solid;
  border-color: rgba(255, 255, 255, 1);
}
.more {
  font-size: 0.78vw;
  color: #ffffff;
  text-align: left;
}
.title-1 {
  position: relative;
  align-self: center;
  overflow: hidden;
  height: 3.54vw;
  max-width: 29.38vw;
  margin-top: 4.58vw;
  font-size: 2.08vw;
  color: #333333;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.submain-item-0 {
  position: relative;
  align-items: flex-start;
  margin-top: 2.08vw;
}
.cover {
  width: 44.64vw;
  height: 27.34vw;
  margin-right: 4.69vw;
}
.block-3 {
  align-items: flex-start;
  margin-top: 9.06vw;
  font-weight: 500;
  white-space: nowrap;
}
.caption-1 {
  position: relative;
  overflow: hidden;
  height: 1.67vw;
  max-width: 29.48vw;
  font-size: 1.46vw;
  color: #1f4266;
  text-align: left;
  text-overflow: ellipsis;
}
.article-1 {
  position: relative;
  display: -webkit-inline-box;
  overflow: hidden;
  width: 29.48vw;
  height: 4.06vw;
  margin-top: 1.25vw;
  font-size: 0.89vw;
  color: #787878;
  text-align: left;
  white-space: normal;
  text-overflow: ellipsis;
  webkit-box-orient: vertical;
  webkit-line-clamp: 3;
}
.container-2 {
  position: relative;
  align-items: center;
  height: 1.93vw;
  padding-left: 2.03vw;
  padding-right: 2.03vw;
  margin-top: 2.08vw;
  background-color: #ff8934;
  line-height: 0.78vw;
  border-radius: 0.21vw;
  box-sizing: border-box;
  border-width: 0.05vw;
  border-style: solid;
  border-color: rgba(255, 137, 52, 1);
}
.detail {
  font-size: 0.78vw;
  color: #ffffff;
  text-align: left;
}
.row-item-0 {
  position: relative;
  align-items: flex-start;
  align-self: flex-end;
  margin-right: 2.71vw;
}
.block-4 {
  align-items: flex-start;
  margin-top: 8.75vw;
  margin-right: 0.94vw;
  font-weight: 500;
  white-space: nowrap;
}
.title-2 {
  position: relative;
  overflow: hidden;
  height: 1.67vw;
  max-width: 29.58vw;
  font-size: 1.46vw;
  color: #1f4266;
  text-align: left;
  text-overflow: ellipsis;
}
.article-2 {
  position: relative;
  display: -webkit-inline-box;
  overflow: hidden;
  width: 29.58vw;
  height: 5.42vw;
  margin-top: 1.25vw;
  font-size: 0.89vw;
  color: #787878;
  text-align: left;
  white-space: normal;
  text-overflow: ellipsis;
  webkit-box-orient: vertical;
  webkit-line-clamp: 4;
}
.container-3 {
  position: relative;
  align-items: center;
  height: 1.93vw;
  padding-left: 2.03vw;
  padding-right: 2.03vw;
  margin-top: 2.08vw;
  background-color: #ff8934;
  line-height: 0.78vw;
  border-radius: 0.21vw;
  box-sizing: border-box;
  border-width: 0.05vw;
  border-style: solid;
  border-color: rgba(255, 137, 52, 1);
}
.detail-1 {
  font-size: 0.78vw;
  color: #ffffff;
  text-align: left;
}
.banner-1 {
  width: 44.64vw;
  height: 27.29vw;
}
.submain-item-1 {
  position: relative;
  align-items: flex-start;
  margin-top: 0.05vw;
}
.cover-1 {
  width: 44.64vw;
  height: 27.29vw;
  margin-right: 5.94vw;
}
.block-5 {
  align-items: flex-start;
  margin-top: 8.02vw;
  font-weight: 500;
  white-space: nowrap;
}
.caption-2 {
  position: relative;
  overflow: hidden;
  height: 1.67vw;
  max-width: 33.96vw;
  font-size: 1.46vw;
  color: #1f4266;
  text-align: left;
  text-overflow: ellipsis;
}
.article-3 {
  position: relative;
  display: -webkit-inline-box;
  overflow: hidden;
  width: 33.96vw;
  height: 5.42vw;
  margin-top: 1.25vw;
  font-size: 0.89vw;
  color: #787878;
  text-align: left;
  white-space: normal;
  text-overflow: ellipsis;
  webkit-box-orient: vertical;
  webkit-line-clamp: 4;
}
.container-4 {
  position: relative;
  align-items: center;
  height: 1.93vw;
  padding-left: 2.03vw;
  padding-right: 2.03vw;
  margin-top: 2.08vw;
  background-color: #ff8934;
  line-height: 0.78vw;
  border-radius: 0.21vw;
  box-sizing: border-box;
  border-width: 0.05vw;
  border-style: solid;
  border-color: rgba(255, 137, 52, 1);
}
.detail-2 {
  font-size: 0.78vw;
  color: #ffffff;
  text-align: left;
}
.row-item-1 {
  position: relative;
  align-items: flex-start;
  align-self: flex-end;
  margin-top: 0.05vw;
  margin-right: 3.13vw;
}
.block-6 {
  align-items: flex-start;
  margin-top: 10.42vw;
  margin-right: 0.89vw;
  font-weight: 500;
  white-space: nowrap;
}
.title-3 {
  position: relative;
  overflow: hidden;
  height: 1.67vw;
  max-width: 32.29vw;
  font-size: 1.46vw;
  color: #1f4266;
  text-align: left;
  text-overflow: ellipsis;
}
.article-4 {
  position: relative;
  display: -webkit-inline-box;
  overflow: hidden;
  width: 32.29vw;
  height: 4.06vw;
  margin-top: 1.25vw;
  font-size: 0.89vw;
  color: #787878;
  text-align: left;
  white-space: normal;
  text-overflow: ellipsis;
  webkit-box-orient: vertical;
  webkit-line-clamp: 3;
}
.container-5 {
  position: relative;
  align-items: center;
  height: 1.93vw;
  padding-left: 2.03vw;
  padding-right: 2.03vw;
  margin-top: 2.08vw;
  background-color: #ff8934;
  line-height: 0.78vw;
  border-radius: 0.21vw;
  box-sizing: border-box;
  border-width: 0.05vw;
  border-style: solid;
  border-color: rgba(255, 137, 52, 1);
}
.detail-3 {
  font-size: 0.78vw;
  color: #ffffff;
  text-align: left;
}
.banner-2 {
  width: 44.64vw;
  height: 27.29vw;
}
.body-item-1 {
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 5vw;
  margin-top: 6.04vw;
}
.long-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 5vw;
}
.caption-3 {
  position: relative;
  margin-top: -0.21vw;
  margin-right: 1.93vw;
  font-size: 1.67vw;
  line-height: 1.67vw;
  color: #ffffff;
  text-align: left;
  white-space: pre;
}
.block-7 {
  position: relative;
  align-items: center;
  height: 1.93vw;
  padding-left: 2.03vw;
  padding-right: 2.03vw;
  margin-top: -0.05vw;
  /* background-color: #ffb445; */
  font-weight: 500;
  line-height: 0.78vw;
  white-space: nowrap;
  border-radius: 0.21vw;
  box-sizing: border-box;
  border-width: 0.05vw;
  border-style: solid;
  border-color: rgba(255, 255, 255, 1);
}
.more-1 {
  font-size: 0.78vw;
  color: #ffffff;
  text-align: left;
}
.row-4 {
  position: relative;
  justify-content: center;
  align-items: flex-start;
  align-self: center;
  width: 99.84vw;
  height: 44.48vw;
  /* background-image: url(../images/img_38.png); */
  background-size: contain;
}
.block-8 {
  align-items: center;
  margin-top: 6.56vw;
}
.tag-3 {
  position: relative;
  overflow: hidden;
  height: 1.77vw;
  max-width: 15.42vw;
  font-size: 1.25vw;
  font-weight: 500;
  color: #333333;
  text-align: center;
  white-space: pre;
  text-overflow: ellipsis;
}
.item-long {
  position: relative;
  width: 16.04vw;
  height: 28.02vw;
  margin-top: 1.25vw;
}
.block-9 {
  align-items: center;
  margin-top: 15.05vw;
  margin-right: -0.05vw;
}
.container-6 {
  position: relative;
  align-items: center;
  height: 1.67vw;
  font-weight: 500;
  line-height: 1.25vw;
  white-space: nowrap;
}
.label-2 {
  margin-right: 5.89vw;
  font-size: 1.25vw;
  color: #333333;
  text-align: center;
}
.meta {
  font-size: 1.25vw;
  color: #333333;
  text-align: center;
}
.background-wrapper {
  position: relative;
  justify-content: center;
  align-items: flex-start;
  width: 41.51vw;
  height: 14.58vw;
  margin-top: 0.1vw;
}
.background {
  position: absolute;
  left: 0;
  top: 0;
  width: 41.51vw;
  height: 14.58vw;
}
.product-long {
  position: relative;
  width: 10.73vw;
  height: 13.54vw;
  margin-top: 1.15vw;
  margin-right: 3.02vw;
}
.layer-wrapper {
  position: relative;
  justify-content: center;
  align-items: flex-start;
  width: 10.73vw;
  height: 13.54vw;
  margin-top: 1.15vw;
}
.layer-1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 10.73vw;
  height: 13.54vw;
}
.picture {
  position: relative;
  width: 6.25vw;
  height: 6.25vw;
  margin-top: 3.18vw;
}
.block-10 {
  position: relative;
  align-items: center;
  width: 21.88vw;
  margin-top: 6.46vw;
}
.action-bg {
  position: absolute;
  top: 23.39vw;
  align-self: center;
  width: 18.85vw;
  height: 3.96vw;
}
.entry-pic {
  position: absolute;
  top: 5.63vw;
  right: 1.56vw;
  width: 8.85vw;
  height: 5.42vw;
}
.subtitle {
  position: relative;
  overflow: hidden;
  height: 1.67vw;
  max-width: 21.25vw;
  font-size: 1.25vw;
  font-weight: 500;
  color: #333333;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.container-7 {
  position: relative;
  align-items: center;
  width: 21.88vw;
  height: 28.02vw;
  margin-top: 1.25vw;
  background-color: #ffffff;
  border-radius: 1.04vw;
  border-width: 0.05vw;
  border-style: solid;
  border-color: rgba(38, 82, 149, 1);
}
.wrapper-2 {
  position: relative;
  align-items: flex-start;
  margin-top: 2.03vw;
}
.group-item-0 {
  position: relative;
  justify-content: center;
  align-items: center;
}
.entry-pic-wrapper-item-0 {
  justify-content: center;
  align-items: flex-start;
  width: 8.85vw;
  height: 7.4vw;
  margin-right: 1.25vw;
  background-color: #ffffff;
  box-shadow: 0 0 0.21vw 0 rgba(0, 0, 0, 0.07000000029802322);
  border-width: 0.05vw;
  border-style: solid;
  border-color: rgba(38, 82, 149, 1);
}
.entry-pic-1 {
  width: 8.8vw;
  height: 5.68vw;
  margin-top: 0.57vw;
}
.entry-pic-wrapper-item-1 {
  justify-content: center;
  align-items: flex-start;
  height: 7.4vw;
  background-color: #ffffff;
  box-shadow: 0 0 0.21vw 0 rgba(0, 0, 0, 0.07000000029802322);
  border-width: 0.05vw;
  border-style: solid;
  border-color: rgba(38, 82, 149, 1);
}
.entry-pic-2 {
  width: 8.85vw;
  height: 5.42vw;
  margin-top: 0.57vw;
}
.group-item-1 {
  position: relative;
  justify-content: center;
  align-items: center;
  margin-top: 1.25vw;
}
.view-item-0 {
  justify-content: center;
  align-items: center;
  width: 8.85vw;
  height: 7.4vw;
  margin-right: 1.25vw;
  background-color: #ffffff;
  box-shadow: 0 0 0.21vw 0 rgba(0, 0, 0, 0.07000000029802322);
  border-width: 0.05vw;
  border-style: solid;
  border-color: rgba(38, 82, 149, 1);
}
.logo {
  width: 8.28vw;
  height: 5.31vw;
  margin-top: 0.05vw;
}
.view-item-1 {
  justify-content: center;
  align-items: center;
  width: 8.85vw;
  height: 7.4vw;
  background-color: #ffffff;
  box-shadow: 0 0 0.21vw 0 rgba(0, 0, 0, 0.07000000029802322);
  border-width: 0.05vw;
  border-style: solid;
  border-color: rgba(38, 82, 149, 1);
}
.logo-1 {
  width: 7.03vw;
  height: 5.78vw;
  margin-top: 0;
}
.button-bg-wrapper {
  position: relative;
  justify-content: center;
  align-items: flex-start;
  width: 18.96vw;
  height: 6.56vw;
  margin-top: 1.25vw;
  background-color: #ffffff;
  box-shadow: 0 0 0.21vw 0 rgba(0, 0, 0, 0.07000000029802322);
  border-width: 0.05vw;
  border-style: solid;
  border-color: rgba(38, 82, 149, 1);
}
.button-bg {
  width: 18.85vw;
  height: 3.96vw;
  margin-top: 1.04vw;
}
.title-4 {
  position: relative;
  overflow: hidden;
  height: 2.92vw;
  max-width: 26.56vw;
  margin-top: 6.04vw;
  margin-left: 36.72vw;
  font-size: 2.08vw;
  color: #ff8934;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.main {
  position: relative;
  align-items: flex-start;
  align-self: center;
  margin-top: 4.58vw;
}
.block-item-0 {
  align-items: flex-start;
  width: 12.5vw;
  margin-right: 6.93vw;
}
.container-8 {
  position: relative;
  align-items: center;
  width: 2.5vw;
  height: 2.5vw;
  margin-left: 5.36vw;
  background-color: #ffffff;
  /* background-image: url(../images/img_39.png); */
  background-size: contain;
}
.icon-circle-wrapper {
  position: relative;
  justify-content: center;
  align-items: center;
  width: 1.77vw;
  height: 1.77vw;
  margin-left: 0.36vw;
}
.icon-circle-1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 1.77vw;
  height: 1.77vw;
}
.num {
  position: relative;
  overflow: hidden;
  max-width: 1.15vw;
  font-size: 1.25vw;
  font-weight: 600;
  line-height: 1.25vw;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.image {
  position: relative;
  width: 7.08vw;
  height: 7.08vw;
  margin-top: 1.25vw;
  margin-left: 2.4vw;
}
.info-1 {
  position: relative;
  align-self: center;
  overflow: hidden;
  height: 1.46vw;
  max-width: 9.27vw;
  margin-top: 1.25vw;
  font-size: 1.25vw;
  font-weight: 500;
  line-height: 1.46vw;
  color: #333333;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.summary-2 {
  position: relative;
  display: -webkit-inline-box;
  overflow: hidden;
  width: 12.5vw;
  height: 4.01vw;
  margin-top: 1.25vw;
  font-size: 0.83vw;
  color: #787878;
  text-align: center;
  white-space: normal;
  text-overflow: ellipsis;
  webkit-box-orient: vertical;
  webkit-line-clamp: 3;
}
.block-item-1 {
  align-items: flex-start;
  width: 12.5vw;
  margin-right: 6.98vw;
}
.container-9 {
  position: relative;
  align-items: center;
  align-self: center;
  width: 2.5vw;
  height: 2.5vw;
  background-color: #ffffff;
  /* background-image: url(../images/img_40.png); */
  background-size: contain;
}
.icon-circle-wrapper-1 {
  position: relative;
  align-items: center;
  width: 1.77vw;
  height: 1.77vw;
  margin-left: 0.36vw;
}
.icon-circle-2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 1.77vw;
  height: 1.77vw;
}
.num-1 {
  position: relative;
  overflow: hidden;
  max-width: 0.94vw;
  margin-left: 0.57vw;
  font-size: 1.25vw;
  font-weight: 600;
  line-height: 1.25vw;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.figure {
  position: relative;
  width: 7.08vw;
  height: 7.08vw;
  margin-top: 1.25vw;
  margin-left: 2.29vw;
}
.word-2 {
  position: relative;
  align-self: center;
  overflow: hidden;
  height: 1.46vw;
  max-width: 5.73vw;
  margin-top: 1.25vw;
  font-size: 1.25vw;
  font-weight: 500;
  line-height: 1.46vw;
  color: #333333;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.summary-3 {
  position: relative;
  display: -webkit-inline-box;
  overflow: hidden;
  width: 12.5vw;
  height: 3.96vw;
  margin-top: 1.25vw;
  font-size: 0.83vw;
  color: #787878;
  text-align: center;
  white-space: normal;
  text-overflow: ellipsis;
  webkit-box-orient: vertical;
  webkit-line-clamp: 3;
}
.block-item-2 {
  align-items: center;
  margin-right: 7.6vw;
}
.container-10 {
  position: relative;
  align-items: center;
  width: 2.5vw;
  height: 2.5vw;
  background-color: #ffffff;
  /* background-image: url(../images/img_41.png); */
  background-size: contain;
}
.icon-circle-wrapper-2 {
  position: relative;
  align-items: center;
  width: 1.77vw;
  height: 1.77vw;
  margin-left: 0.36vw;
}
.icon-circle-3 {
  position: absolute;
  left: 0;
  top: 0;
  width: 1.77vw;
  height: 1.77vw;
}
.num-2 {
  position: relative;
  overflow: hidden;
  max-width: 0.94vw;
  margin-left: 0.57vw;
  font-size: 1.25vw;
  font-weight: 600;
  line-height: 1.25vw;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.bitmap {
  position: relative;
  width: 7.08vw;
  height: 7.08vw;
  margin-top: 1.25vw;
}
.tag-4 {
  position: relative;
  overflow: hidden;
  height: 1.46vw;
  max-width: 11.88vw;
  margin-top: 1.25vw;
  font-size: 1.25vw;
  font-weight: 500;
  line-height: 1.46vw;
  color: #333333;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.summary-4 {
  position: relative;
  display: -webkit-inline-box;
  overflow: hidden;
  width: 12.5vw;
  height: 3.96vw;
  margin-top: 1.25vw;
  font-size: 0.83vw;
  color: #787878;
  text-align: center;
  white-space: normal;
  text-overflow: ellipsis;
  webkit-box-orient: vertical;
  webkit-line-clamp: 3;
}
.block-item-3 {
  align-items: flex-start;
  width: 12.5vw;
}
.container-11 {
  position: relative;
  align-items: center;
  width: 2.5vw;
  height: 2.5vw;
  margin-left: 3.7vw;
  background-color: #ffffff;
  /* background-image: url(../images/img_42.png); */
  background-size: contain;
}
.icon-minus-wrapper {
  position: relative;
  align-items: center;
  width: 1.77vw;
  height: 1.77vw;
  margin-left: 0.36vw;
}
.icon-minus {
  position: absolute;
  left: 0;
  top: 0;
  width: 1.77vw;
  height: 1.77vw;
}
.num-3 {
  position: relative;
  overflow: hidden;
  max-width: 0.83vw;
  margin-left: 0.52vw;
  font-size: 1.25vw;
  font-weight: 600;
  line-height: 1.25vw;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.picture-1 {
  position: relative;
  width: 7.08vw;
  height: 7.08vw;
  margin-top: 1.25vw;
  margin-left: 2.29vw;
}
.label-3 {
  position: relative;
  align-self: center;
  overflow: hidden;
  height: 1.46vw;
  max-width: 5.73vw;
  margin-top: 1.25vw;
  font-size: 1.25vw;
  font-weight: 500;
  line-height: 1.46vw;
  color: #333333;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.summary-5 {
  position: relative;
  display: -webkit-inline-box;
  overflow: hidden;
  width: 12.5vw;
  height: 3.8vw;
  margin-top: 0.89vw;
  font-size: 0.83vw;
  color: #787878;
  text-align: center;
  text-overflow: ellipsis;
  webkit-box-orient: vertical;
  webkit-line-clamp: 3;
}
.row-5 {
  position: relative;
  align-items: center;
  align-self: center;
  height: 1.88vw;
  margin-top: 8.7vw;
  white-space: nowrap;
}
.block-11 {
  position: relative;
  align-items: flex-start;
  margin-right: 1.35vw;
}
.benifit {
  position: relative;
  font-size: 2.19vw;
  font-weight: 600;
  color: #335fa7;
}
.benifit-next {
  position: absolute;
  top: 0;
  right: 0.05vw;
  font-size: 2.19vw;
  font-weight: 500;
  color: #335fa7;
}
.caption-4 {
  font-size: 2.19vw;
  font-weight: 300;
  line-height: 2.19vw;
  color: #ff8a35;
}
.row-6 {
  position: relative;
  align-items: center;
  align-self: center;
  margin-top: 4.58vw;
}
.block-item-0-1 {
  align-items: flex-start;
  margin-right: 2.29vw;
}
.entry-pic-wrapper {
  position: relative;
  justify-content: center;
  align-items: center;
  width: 38.23vw;
  height: 21.56vw;
  background-color: #f9f9f9;
}
.entry-pic-3 {
  width: 22.29vw;
  height: 16.51vw;
  margin-top: 0.05vw;
}
.meta-1 {
  position: relative;
  overflow: hidden;
  height: 1.88vw;
  max-width: 37.6vw;
  margin-top: 2.5vw;
  margin-left: 0.63vw;
  font-size: 1.25vw;
  font-weight: 500;
  color: #333333;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.organization {
  position: relative;
  display: -webkit-inline-box;
  overflow: hidden;
  width: 38.23vw;
  height: 3.33vw;
  margin-top: 1.25vw;
  margin-left: 0.63vw;
  font-size: 0.83vw;
  color: #787878;
  text-align: left;
  text-overflow: ellipsis;
  webkit-box-orient: vertical;
  webkit-line-clamp: 2;
}
.container-12 {
  position: relative;
  align-items: center;
  margin-top: 3.33vw;
  margin-left: 0.63vw;
}
.subtitle-wrapper {
  align-items: flex-start;
  width: 5.52vw;
  margin-right: 1.98vw;
  white-space: nowrap;
}
.subtitle-1 {
  overflow: hidden;
  height: 1.25vw;
  max-width: 5.52vw;
  font-size: 0.83vw;
  color: #ff8934;
  text-align: left;
  text-overflow: ellipsis;
}
.icon-chat-wrapper {
  align-items: center;
  width: 0;
  height: 1.25vw;
}
.icon-chat {
  width: 1.98vw;
  height: 0.1vw;
  margin-top: 0.1vw;
  margin-left: -1.98vw;
}
.block-item-1-1 {
  align-items: flex-start;
}
.entry-pic-wrapper-1 {
  position: relative;
  justify-content: center;
  align-items: center;
  width: 38.23vw;
  height: 21.56vw;
  background-color: #f9f9f9;
}
.entry-pic-4 {
  width: 22.29vw;
  height: 18.54vw;
}
.info-2 {
  position: relative;
  overflow: hidden;
  height: 1.88vw;
  max-width: 37.6vw;
  margin-top: 2.5vw;
  margin-left: 0.63vw;
  font-size: 1.25vw;
  font-weight: 500;
  color: #333333;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.article-5 {
  position: relative;
  display: -webkit-inline-box;
  overflow: hidden;
  width: 38.23vw;
  height: 3.33vw;
  margin-top: 1.25vw;
  margin-left: 0.63vw;
  font-size: 0.83vw;
  color: #787878;
  text-align: left;
  text-overflow: ellipsis;
  webkit-box-orient: vertical;
  webkit-line-clamp: 2;
}
.container-13 {
  position: relative;
  align-items: center;
  margin-top: 3.33vw;
  margin-left: 0.63vw;
}
.desc-wrapper {
  align-items: flex-start;
  width: 9.69vw;
  margin-right: 1.98vw;
  white-space: nowrap;
}
.desc {
  overflow: hidden;
  height: 1.25vw;
  max-width: 9.69vw;
  font-size: 0.83vw;
  color: #ff8934;
  text-align: left;
  text-overflow: ellipsis;
}
.wrapper-3 {
  align-items: center;
  width: 0;
  height: 1.25vw;
}
.horizontal-line-2 {
  width: 1.98vw;
  height: 0.1vw;
  margin-top: 0.1vw;
  margin-left: -1.98vw;
}
.row-7 {
  position: relative;
  align-items: flex-start;
  width: 100vw;
  height: 21.77vw;
  margin-top: 6.46vw;
  white-space: nowrap;
}
.background-1 {
  position: absolute;
  top: 0;
  align-self: center;
  width: 100vw;
  height: 21.77vw;
  white-space: normal;
}
.title-5 {
  position: relative;
  overflow: hidden;
  height: 3.49vw;
  max-width: 13.75vw;
  margin-top: 4.11vw;
  margin-left: 43.13vw;
  font-size: 2.5vw;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  text-overflow: ellipsis;
}
.summary-6 {
  position: relative;
  overflow: hidden;
  height: 1.04vw;
  max-width: 28.23vw;
  margin-top: 1.04vw;
  margin-left: 35.89vw;
  font-size: 0.94vw;
  color: #ffffff;
  text-align: center;
  text-overflow: ellipsis;
}
.footer {
  position: relative;
  align-items: flex-end;
}
.block-12 {
  position: relative;
  align-items: flex-start;
  background-color: #ffffff;
}
.container-14 {
  position: relative;
  align-items: flex-start;
  width: 100vw;
  height: 27.19vw;
}
.wrapper-4 {
  position: relative;
  align-items: flex-start;
  align-self: center;
  margin-top: 5.16vw;
}
.meta-2 {
  margin-top: 5.83vw;
  margin-right: 27.97vw;
  font-size: 1.04vw;
  color: rgba(89, 106, 135, 0.72);
  text-align: justified;
  white-space: nowrap;
}
.group-2 {
  align-items: flex-start;
  margin-right: 8.96vw;
}
.subtitle-2 {
  position: relative;
  font-size: 1.04vw;
  font-weight: 500;
  color: #596a87;
  text-align: justified;
  white-space: nowrap;
}
.word-3 {
  position: relative;
  overflow: hidden;
  height: 1.04vw;
  max-width: 8.02vw;
  margin-top: 1.67vw;
  font-size: 0.73vw;
  color: #1f4266;
  text-align: justified;
  white-space: pre;
  text-overflow: ellipsis;
}
.tag-5 {
  position: relative;
  overflow: hidden;
  height: 1.04vw;
  max-width: 8.02vw;
  margin-top: 0.83vw;
  font-size: 0.73vw;
  color: #1f4266;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.info-3 {
  position: relative;
  overflow: hidden;
  height: 1.04vw;
  max-width: 8.02vw;
  margin-top: 0.83vw;
  font-size: 0.73vw;
  color: #1f4266;
  text-align: justified;
  white-space: pre;
  text-overflow: ellipsis;
}
.group-3 {
  align-items: flex-start;
  margin-right: 5.99vw;
}
.label-4 {
  position: relative;
  font-size: 1.04vw;
  font-weight: 500;
  color: #596a87;
  text-align: justified;
  white-space: pre;
}
.word-4 {
  position: relative;
  overflow: hidden;
  height: 1.04vw;
  max-width: 4.95vw;
  margin-top: 1.67vw;
  font-size: 0.73vw;
  color: #1f4266;
  text-align: justified;
  white-space: pre;
  text-overflow: ellipsis;
}
.tag-6 {
  position: relative;
  overflow: hidden;
  height: 1.04vw;
  max-width: 4.95vw;
  margin-top: 0.83vw;
  font-size: 0.73vw;
  color: #1f4266;
  text-align: justified;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.label-5 {
  position: relative;
  overflow: hidden;
  height: 1.04vw;
  max-width: 4.95vw;
  margin-top: 0.83vw;
  font-size: 0.73vw;
  color: #1f4266;
  text-align: justified;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.group-4 {
  align-items: flex-start;
  white-space: nowrap;
}
.word-5 {
  position: relative;
  overflow: hidden;
  height: 1.46vw;
  max-width: 4.38vw;
  font-size: 1.04vw;
  font-weight: 500;
  color: #596a87;
  text-align: justified;
  text-overflow: ellipsis;
}
.tag-7 {
  position: relative;
  overflow: hidden;
  height: 1.04vw;
  max-width: 4.38vw;
  margin-top: 1.67vw;
  font-size: 0.73vw;
  color: #1f4266;
  text-align: justified;
  text-overflow: ellipsis;
}
.meta-3 {
  position: relative;
  margin-top: 0.83vw;
  font-size: 0.73vw;
  color: #1f4266;
  text-align: justified;
}
.wrapper-5 {
  position: relative;
  align-items: flex-start;
  margin-top: 0.68vw;
  margin-left: 50.21vw;
  line-height: 0.73vw;
}
.group-5 {
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 21.09vw;
  height: 1.2vw;
  white-space: nowrap;
}
.subtitle-3 {
  margin-top: -0.16vw;
  font-size: 0.73vw;
  color: #1f4266;
  text-align: justified;
}
.label-6 {
  margin-top: 0.16vw;
  font-size: 0.73vw;
  color: #1f4266;
  text-align: justified;
}
.group-6 {
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 20.63vw;
  height: 1.35vw;
  margin-top: 0.52vw;
}
.word-6 {
  margin-top: -0.31vw;
  font-size: 0.73vw;
  color: #1f4266;
  text-align: justified;
  white-space: nowrap;
}
.tag-8 {
  margin-top: 0.31vw;
  font-size: 0.73vw;
  color: #1f4266;
  text-align: justified;
  white-space: pre;
}
.group-7 {
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 20.63vw;
  height: 1.35vw;
  margin-top: 0.52vw;
  white-space: nowrap;
}
.info-4 {
  margin-top: -0.31vw;
  font-size: 0.73vw;
  color: #1f4266;
  text-align: justified;
}
.label-7 {
  margin-top: 0.31vw;
  font-size: 0.73vw;
  color: #1f4266;
  text-align: justified;
}
.group-8 {
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 22.08vw;
  height: 1.35vw;
  margin-top: 0.52vw;
  white-space: pre;
}
.word-7 {
  margin-top: -0.31vw;
  font-size: 0.73vw;
  color: #1f4266;
  text-align: justified;
}
.meta-4 {
  margin-top: 0.31vw;
  font-size: 0.73vw;
  color: #1f4266;
  text-align: justified;
}
.tag-9 {
  position: relative;
  overflow: hidden;
  height: 1.04vw;
  max-width: 9.06vw;
  margin-top: 0.36vw;
  margin-left: 50.21vw;
  font-size: 0.73vw;
  color: #1f4266;
  text-align: justified;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.container-15 {
  position: relative;
  align-items: flex-start;
  height: 4.38vw;
  margin-top: 0.05vw;
}
.horizontal-line-3 {
  position: relative;
  width: 100vw;
  height: 0.05vw;
  margin-top: -0.05vw;
  background-color: #596a87;
}
.wrapper-6 {
  position: relative;
  align-items: center;
  margin-top: 1.3vw;
  margin-left: 18.75vw;
  line-height: 0.63vw;
  white-space: nowrap;
}
.group-9 {
  align-items: center;
  height: 0.94vw;
  margin-right: 19.22vw;
}
.location {
  margin-right: 1.25vw;
  font-size: 0.63vw;
  color: #596a87;
  text-align: justified;
}
.detail-4 {
  font-size: 0.63vw;
  color: #596a87;
  text-align: justified;
}
.label-8 {
  margin-right: 5.26vw;
  font-size: 0.63vw;
  color: #ee7432;
}
.address {
  font-size: 0.63vw;
  color: #596a87;
}
.word-8 {
  position: absolute;
  left: 31.51vw;
  top: 5.21vw;
  font-size: 1.04vw;
  font-weight: 500;
  color: #596a87;
  text-align: justified;
  white-space: nowrap;
}
.tag-10 {
  position: absolute;
  left: 31.51vw;
  top: 10.63vw;
  font-size: 0.73vw;
  color: #1f4266;
  text-align: justified;
  white-space: pre;
}
.caption-5 {
  position: absolute;
  left: 31.51vw;
  top: 8.33vw;
  font-size: 1.35vw;
  font-weight: 600;
  color: #1f4266;
  text-align: justified;
  white-space: pre;
}
.tag-wrapper-1 {
  position: absolute;
  left: 31.51vw;
  top: 13.33vw;
  align-items: center;
  height: 1.93vw;
  padding-left: 2.03vw;
  padding-right: 2.03vw;
  /* background-color: #ffb445; */
  font-weight: 500;
  line-height: 0.78vw;
  white-space: nowrap;
  border-radius: 0.21vw;
  box-sizing: border-box;
  border-width: 0.05vw;
  border-style: solid;
  border-color: rgba(255, 137, 52, 1);
}
.tag-11 {
  font-size: 0.78vw;
  color: #ff8934;
  text-align: left;
}
